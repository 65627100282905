// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading h1 {
  animation: headingText 0.8s cubic-bezier(0, 0, 0.41, 1.51);
}

@keyframes headingText {
  from {
    transform: translate(0px, 40px) scale(2);
    opacity: 0;
  }
  to {
    transform: translate(0px, 0px) scale(2);
    opacity: 1;
  }
}
.logo, .register, .heading img, #copyright {
  animation: otherElements 1.5s linear;
}

@keyframes otherElements {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./css/animation.css"],"names":[],"mappings":"AAAA;EACI,0DAAA;AACJ;;AACA;EACI;IACI,wCAAA;IACA,UAAA;EAEN;EAAE;IACI,uCAAA;IACA,UAAA;EAEN;AACF;AACA;EACI,oCAAA;AACJ;;AACA;EACI;IACI,UAAA;EAEN;EAAE;IACI,UAAA;EAEN;AACF","sourcesContent":[".heading h1 {\n    animation: headingText .8s cubic-bezier(0, 0, 0.41, 1.51);\n}\n@keyframes headingText {\n    from {\n        transform: translate(0px, 40px) scale(2);\n        opacity: 0;\n    }\n    to {\n        transform: translate(0px, 0px) scale(2);\n        opacity: 1;\n    }\n}\n\n.logo, .register, .heading img, #copyright {\n    animation: otherElements 1.5s linear\n}\n@keyframes otherElements {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
